import React from "react";
import "./ConsentPage.scss";
import { Button } from "@streetsheaver/sh-ui";

export default function Home() {
	const redirect = new URL(window.location.protocol + "//" + window.location.host);
	const loginLink = new URL(
		`https://login.microsoftonline.com/organizations/v2.0/adminconsent?client_id=835fd79b-9087-406b-889e-8167cfbf864d&redirect_uri=${redirect}&state=123&scope=api://43e92ffc-e310-41d7-b9eb-b1ae93127b50/.default`
	);

	const params = new URLSearchParams(document.location.search);
	const scope = params.get("scope");
	const error = params.get("error");
	const errorDescription = params.get("error_description");

	if (scope) window.location.href = "https://reports.streets-heaver.com";

	if (error && errorDescription)
		return (
			<div className='errorHandling'>
				<div className='errorHandling__content'>
					<h1>ERROR - {error?.replace("_", " ")?.toUpperCase()}</h1>
					<pre>{errorDescription}</pre>
					<a rel='noopener noreferrer' href={loginLink}>
						<div>
							<Button type={"CTA"} text={"Try again"} />
						</div>
					</a>
				</div>
			</div>
		);

	return (
		<div className='consent'>
			<p>Please consent to use Report Generator</p>
			<div>
				<a rel='noopener noreferrer' href={loginLink}>
					<Button type={"CTA"} text={"Provide Admin Consent"} />
				</a>
			</div>
		</div>
	);
}
