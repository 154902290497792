import { Header } from "@streetsheaver/sh-ui";
import "./App.scss";
import RepGenLogo from "./images/RepGen.png";
import { Outlet } from "react-router";

function App() {
	return (
		<div className='lightRepGen'>
			<Header appName={"Report Generator"} extraText={"Admin Consent"} appLogo={RepGenLogo} searchBoxEnabled={false} />
			<div className='main'>
				<Outlet />
			</div>
		</div>
	);
}

export default App;
